export const tableElements_en = [
  { id: 1, service: "Scaling with ultrasonic", price: "15 €" },
  {
    id: 2,
    service: "Scaling with ultrasonic plus air flow stain removal",
    price: "30 €",
  },
  { id: 3, service: "First grade with one surface", price: "30 €" },
  { id: 4, service: "First grade with two surfaces", price: "35 €" },
  { id: 5, service: "First grade with three surfaces", price: "40 €" },
  { id: 6, service: "Second grade with one surface", price: "45 €" },
  { id: 7, service: "Second grade with two surfaces", price: "50 €" },
  { id: 8, service: "Second grade with three surfaces", price: "55 €" },
  { id: 9, service: "Third and fourth grade with one canal", price: "15 €" },
  { id: 10, service: "Third and fourth grade with two canals", price: "30 €" },
  {
    id: 11,
    service: "Third and fourth grade with three canals",
    price: "45 €",
  },
  { id: 12, service: "Partial denture one element", price: "20 €" },
  { id: 13, service: "Total denture one jaw", price: "200 €" },
  { id: 14, service: "Total denture both jaws", price: "450 €" },
  { id: 15, service: "Skeleton denture one jaw", price: "250 €" },
  { id: 16, service: "Skeleton denture both jaws", price: "500 €" },
  { id: 17, service: "Elastic denture one jaw", price: "300 €" },
  { id: 18, service: "Elastic denture both jaws", price: "600 €" },
  { id: 19, service: "Metal ceramic crown Co-Cr per element", price: "100 €" },
  { id: 20, service: "Zirconium ceramic crown per element", price: "250 €" },
  { id: 21, service: "Palladium ceramic crown per element", price: "300 €" },
  { id: 22, service: "E-max crown per element", price: "350 €" },
  { id: 23, service: "Zirconium veneers", price: "220 €" },
  { id: 24, service: "E-max veneers", price: "320 €" },
  {
    id: 25,
    service:
      "Professional bleaching in clinic one session, available up to 4 sessions",
    price: "50 €",
  },
  { id: 26, service: "Bruxism night guard", price: "70 €" },
  { id: 27, service: "Tooth extraction", price: "10 €" },
  { id: 28, service: "Molar tooth extraction", price: "20 €" },
  { id: 29, service: "Wisdom tooth removal", price: "50 - 100 €" },
  { id: 30, service: "Radix extraction", price: "25 €" },
  { id: 31, service: "Implant", price: "600 €" },
  { id: 32, service: "Metal Ceramic Crown", price: "150 €" },
  { id: 33, service: "Zirconium Ceramic Crown", price: "250 €" },
];

export const tableElements_sq = [
  { id: 1, service: "Pastrim gurëzash me ultratinguj", price: "1 500 LEK" },
  {
    id: 2,
    service: "Pastrim gurëzash me ultratinguj plus heqje cipash me ajër",
    price: "3 000 LEK",
  },
  { id: 3, service: "Gradë e parë me 1 siperfaqe", price: "3 000 LEK" },
  { id: 4, service: "Gradë e parë me 2 siperfaqe", price: "3 500 LEK" },
  { id: 5, service: "Gradë e parë me 3 siperfaqe", price: "4 000 LEK" },
  { id: 6, service: "Gradë e dy-të me 1 siperfaqe", price: "4 500 LEK" },
  { id: 7, service: "Gradë e dy-të me 2 siperfaqe", price: "5 000 LEK" },
  { id: 8, service: "Gradë e dy-të me 3 siperfaqe", price: "5 500 LEK" },
  {
    id: 9,
    service: "Gradë e tre-të dhe të 4-të me 1 kanal",
    price: "1 500 LEK",
  },
  {
    id: 10,
    service: "Gradë e 3-të dhe të 4-të me 2 kanale",
    price: "3 000 LEK",
  },
  {
    id: 11,
    service: "Gradë e tre-të dhe të 4-të me 3 kanale",
    price: "4 500 LEK",
  },
  { id: 12, service: "Protezë parciale një element", price: "2 000 LEK" },
  { id: 13, service: "Protezë totale për një nofull", price: "20 000 LEK" },
  { id: 14, service: "Protezë totale komplete çift", price: "45 000 LEK" },
  {
    id: 15,
    service: "Protezë e skeletuar për një nofull",
    price: "25 000 LEK",
  },
  { id: 16, service: "Protezë e skeletuar komplete çift", price: "50 000 LEK" },
  { id: 17, service: "Protezë elastike për një nofull", price: "30 000 LEK" },
  { id: 18, service: "Protezë elastike komplete çift", price: "60 000 LEK" },
  {
    id: 19,
    service: "Kurorë metal qeramike (co-cr) për dhëmb",
    price: "10 000 LEK",
  },
  { id: 20, service: "Kurorë zirkoni qeramike për dhëmb", price: "25 000 LEK" },
  { id: 21, service: "Kurorë palladiumi për dhëmb", price: "30 000 LEK" },
  { id: 22, service: "Kurorë e-max për dhëmb", price: "35 000 LEK" },
  { id: 23, service: "Faseta zirkoni për një fasetë", price: "22 000 LEK" },
  { id: 24, service: "Faseta e-max për një fasetë", price: "32 000 LEK" },
  {
    id: 25,
    service:
      "Zbardhim profesional me llambë në klinikë për një seancë, realizohen deri ne 4 seanca",
    price: "5 000 LEK",
  },
  { id: 26, service: "Shinë relaksuese bruksizmi", price: "7 000 LEK" },
  { id: 27, service: "Heqje Dhëmbi", price: "1 000 LEK" },
  { id: 28, service: "Heqje Dhëmballe", price: "2 000 LEK" },
  { id: 29, service: "Heqje Dhëmballe Pjekurie", price: "5 000 - 10 000 LEK" },
  { id: 30, service: "Heqje Rrënjësh", price: "2 500 LEK" },
  { id: 31, service: "Implant Dentar", price: "60 000 LEK" },
  { id: 32, service: "Kurorë Metal Qeramike", price: "15 000 LEK" },
  { id: 33, service: "Kurorë Zikroni", price: "25 000 LEK" },
];

export const tableElements_it = [
  { id: 1, service: "Pulizia denti ultrasuoni", price: "15 €" },
  {
    id: 2,
    service:
      "Pulizia denti ultrasuoni più rimozione delle macchie con aria compressa",
    price: "30 €",
  },
  { id: 3, service: "Primo grado con una superficie", price: "30 €" },
  { id: 4, service: "Primo grado con due superfici", price: "35 €" },
  { id: 5, service: "Primo grado con tre superfici", price: "40 €" },
  { id: 6, service: "Secondo grado con una superficie", price: "45 €" },
  { id: 7, service: "Secondo grado con due superfici", price: "50 €" },
  { id: 8, service: "Secondo grado con tre superfici", price: "55 €" },
  { id: 9, service: "Terzo e quarto grado con un canale", price: "15 €" },
  { id: 10, service: "Terzo e quarto grado con due canali", price: "30 €" },
  {
    id: 11,
    service: "Terzo e quarto grado con tre canali",
    price: "45 €",
  },
  { id: 12, service: "Protesi parziale un elemento", price: "20 €" },
  { id: 13, service: "Protesi totale una mascella", price: "200 €" },
  { id: 14, service: "Protesi totale entrambe le mascelle", price: "450 €" },
  { id: 15, service: "Protesi scheletrica una mascella", price: "250 €" },
  {
    id: 16,
    service: "Protesi scheletrica entrambe le mascelle",
    price: "500 €",
  },
  { id: 17, service: "Protesi elastica una mascella", price: "300 €" },
  { id: 18, service: "Protesi elastica entrambe le mascelle", price: "600 €" },
  {
    id: 19,
    service: "Corona in metallo ceramica Co-Cr per elemento",
    price: "100 €",
  },
  {
    id: 20,
    service: "Corona in ceramica di zirconio per elemento",
    price: "250 €",
  },
  {
    id: 21,
    service: "Corona in ceramica di palladio per elemento",
    price: "300 €",
  },
  { id: 22, service: "Corona E-max per elemento", price: "350 €" },
  { id: 23, service: "Faccette in zirconio", price: "220 €" },
  { id: 24, service: "Faccette E-max", price: "320 €" },
  {
    id: 25,
    service:
      "Sbiancamento professionale in clinica una seduta (fino a 4 sedute)",
    price: "50 €",
  },
  { id: 26, service: "Apparecchio anti Bruxismo", price: "70 €" },
  { id: 27, service: "Estrazione di un dente", price: "10 €" },
  { id: 28, service: "Estrazione del dente molare", price: "20 €" },
  { id: 29, service: "Rimozione dente del giudizio", price: "50 - 100 €" },
  { id: 30, service: "Estrazione della radice", price: "25 €" },
  { id: 31, service: "Impianto", price: "600 €" },
  { id: 32, service: "Corona in metallo ceramica", price: "150 €" },
  { id: 33, service: "Corona in ceramica di zirconio", price: "250 €" },
];
