import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

const Tourism = () => {
  const { t } = useTranslation();

  return (
    <Container as="section" id="tourism">
      <h1 className="text-center">{t("navbar.tourism")}</h1>
      <hr className="mx-auto" width="20%" />
      <p className="px-lg-5 pt-2">{t("tourism.body")}</p>
    </Container>
  );
};

export default Tourism;
