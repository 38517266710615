import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

library.add(fas, fab);

const NavInfoBar = () => {
  const { t } = useTranslation();

  return (
    <Navbar
      variant="light"
      className="meta d-none d-lg-flex justify-content-between text-secondary p-0"
    >
      <Nav className="ps-3 align-items-center">
        <FontAwesomeIcon
          style={{ color: "white" }}
          icon={["fas", "phone-alt"]}
        />
        <Nav.Link href="tel:+355696511116" className="py-0 text-secondary">
          +355-6965-11116
        </Nav.Link>
        <FontAwesomeIcon
          style={{ color: "white" }}
          icon={["fas", "map-marker-alt"]}
        />
        <Nav.Item className="px-2">Rr Elbasanit, Tirane, AL</Nav.Item>
        <FontAwesomeIcon style={{ color: "white" }} icon={["fas", "clock"]} />
        <Nav.Item className="px-2">
          {t("navbar.hours")} - 08:00 - 20:00
        </Nav.Item>
      </Nav>

      <Nav className="pe-4 align-items-center">
        <Nav.Link
          className="py-0"
          style={{ color: "white" }}
          href="https://www.facebook.com/dr.shkenza/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={["fab", "facebook-f"]} />
        </Nav.Link>
        <Nav.Link
          className="py-0"
          style={{ color: "white" }}
          href="https://www.instagram.com/studio_dentare_dr_shkenza/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default NavInfoBar;
