import Container from "react-bootstrap/esm/Container";
import AboutUs from "./AboutUs";
import Contact from "./contact-map-comp/Contact";
import Gallery from "./Gallery";
import Home from "./home-comp/Home";
import Services from "./services-comp/Services";
import Team from "./Team";
import Tourism from "./Tourism";
import Videos from "./videos-comp/Videos";

function Main() {
  return (
    <Container as="main" fluid className="p-0">
      <Home />
      <AboutUs />
      <Services />
      <Gallery />
      <Team />
      <Tourism />
      <Videos />
      <Contact />
    </Container>
  );
}

export default Main;
