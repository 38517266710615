import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import {
  teamMembers_en,
  teamMembers_it,
  teamMembers_sq,
} from "../data/teamMembers";

const Team = () => {
  const { t, i18n } = useTranslation();

  /**
   * Below the logic to find the right language to display.
   */
  const lngVars = [
    { data: teamMembers_en, variant: "en" },
    { data: teamMembers_sq, variant: "sq" },
    { data: teamMembers_it, variant: "it" },
  ];

  const currentSelLng = lngVars.filter((lng) => lng.variant === i18n.language);

  const [{ data }] = currentSelLng;
  /*********************************************************/

  return (
    <Container as="section" fluid id="ourTeam">
      <h1 className="text-center">{t("navbar.ourTeam")}</h1>
      <Row className="pt-4 justify-content-md-center">
        {data.map(({ id, img, alt, name, description }) => (
          <Col
            key={id}
            lg={3}
            className="d-flex align-items-stretch mb-3 mb-lg-0"
          >
            <Card className="text-center align-items-center pt-3">
              <Image
                src={img}
                roundedCircle
                width="140"
                height="140"
                alt="Team member img"
              />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>Dentist</Card.Text>
                <Card.Text>{description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Team;
