import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import doctorImage from "../../../assets/images/header-doctor.png";
import HomeForm from "./Form";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container fluid as="section" id="home">
      <Row className="justify-content-md-center">
        <Col lg="5" className="text-center">
          <Image
            src={doctorImage}
            alt="Doctor Image"
            className="d-none d-lg-inline"
            fluid
          />
        </Col>
        <Col lg="5" className="my-auto text-center">
          <hr width="20%" className="mx-auto" />
          <Row className="justify-content-md-center">
            <h2>{t("home.titleOne")}</h2>
            <h2>{t("home.titleTwo")}</h2>
          </Row>
          <hr width="20%" className="mx-auto" />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col lg="5" className="mb-3 mb-lg-0">
          <Card
            style={{ backgroundColor: "#436280" }}
            className="text-center text-white mb-3 h-100"
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-evenly info-card">
              <FontAwesomeIcon icon="phone-alt" size="2x" />
              <h1>
                <a href="tel:+355696511116">+355-6965-11116</a>
              </h1>
              <span>{t("home.openInfo")}</span>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5">
          <HomeForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
