import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { PHOTOS } from "../data/galleryImages";

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <Container as="section" fluid id="gallery">
      <h1 className="text-center">{t("navbar.gallery")}</h1>
      <Container className="pt-4 px-lg-5">
        <ImageGallery items={PHOTOS} showPlayButton={false} lazyLoad={true} />
      </Container>
    </Container>
  );
};

export default Gallery;
