import { useEffect, useState } from "react";

const getWindowViewport = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

export default function useWindowViewport() {
  const [windowViewport, setWindowViewport] = useState(getWindowViewport());

  useEffect(() => {
    const handleResize = () => {
      setWindowViewport(getWindowViewport());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowViewport;
}
