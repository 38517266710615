import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { videoIds } from "../../data/videoIds";
import useWindowViewport from "../../hooks/useWindowViewport";
import YoutubeVideo from "./YoutubeVideo";

const Videos = () => {
  const { t } = useTranslation();
  const { width } = useWindowViewport();

  const [showMore, setShowMore] = useState(false);

  /**
   * Below is the logic of how many videos @numberOfVideos to show in base of the current viewport by using
   * the useWindowViewport() hook (https://usehooks.com/useWindowSize/) 3 videos in large screen, 2 medium
   * and 1 small screens.
   *
   * @videoShown is about the videos to be shown in each screen size.
   */
  const numberOfVideos =
    !showMore && width >= 992
      ? 3
      : !showMore && width >= 768 && width < 992
      ? 2
      : !showMore && width < 768
      ? 1
      : videoIds.length;

  const videosShown = videoIds.slice(0, numberOfVideos);
  /*********************************************************************************************************/

  const buttonStyle = {
    backgroundColor: "#436280",
    borderColor: "#436280",
    fontFamily: "baloo",
    fontSize: "1.5rem",
  };

  const buttonStateLogic =
    videosShown.length === videoIds.length ? "hidden" : showMore && "hidden";

  return (
    <Container as="section" fluid id="videos">
      <h1 className="text-center">{t("navbar.videos")}</h1>
      <Container className="pt-4 text-center">
        <Row xs={1} md={2} lg={videoIds.length >= 3 ? 3 : videoIds.length}>
          {videosShown.map((id) => (
            <YoutubeVideo key={id} embedId={id} />
          ))}
        </Row>
        <Button
          style={{
            ...buttonStyle,
            visibility: buttonStateLogic,
          }}
          onClick={() => setShowMore(true)}
        >
          Show More
        </Button>
      </Container>
    </Container>
  );
};

export default Videos;
