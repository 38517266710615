import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const Map = () => {
  const { t } = useTranslation();

  return (
    <MapContainer
      center={[41.323411, 19.824902]}
      zoom={14}
      scrollWheelZoom={false}
      className="rounded"
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[41.323411, 19.824902]}>
        <Popup>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.google.com/search?q=Studio+Dentare+Dr.+Irsid+Shkenza&sxsrf=AOaemvIbeOpFbLvjd3fu-SaB_12QOnDvaQ%3A1635781652653&ei=FAyAYcGKJ-fn7_UP5r2L2Ag&oq=Studio+Dentare+Dr.+Irsid+Shkenza&gs_lcp=Cgdnd3Mtd2l6EAM6BwgjELADECdKBAhBGAFQ9VFYj5IBYOSeAWgDcAB4AIABkQGIAeMDkgEDMS4zmAEAoAEBoAECyAEBwAEB&sclient=gws-wiz&ved=0ahUKEwjB5NfCwffzAhXn87sIHebeAosQ4dUDCA4&uact=5"
          >
            {t("documentTitle")} <strong>Dr. Irsid Shkenza</strong>
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
