import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  tableElements_en,
  tableElements_it,
  tableElements_sq,
} from "../../data/tableElements";

const TableServices = () => {
  const { t, i18n } = useTranslation();

  /**
   * Below the logic to find the right language to display.
   */
  const lngVars = [
    { data: tableElements_en, variant: "en" },
    { data: tableElements_sq, variant: "sq" },
    { data: tableElements_it, variant: "it" },
  ];

  const currentSelLng = lngVars.filter((lng) => lng.variant === i18n.language);

  const [{ data }] = currentSelLng;
  /*********************************************************/

  return (
    <Table striped borderless variant="light" className="h-100">
      <thead>
        <tr>
          <th scope="col">{t("services.service")}</th>
          <th scope="col">{t("services.prize")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ id, service, price }) => (
          <tr key={id}>
            <td>{service}</td>
            <td>{price}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableServices;
