import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Ratio from "react-bootstrap/Ratio";

const YoutubeVideo = ({ embedId }) => (
  <Col className="mb-3">
    <Ratio aspectRatio="16x9">
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${embedId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Ratio>
  </Col>
);

YoutubeVideo.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeVideo;
