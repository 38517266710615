import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Container as="section" id="aboutUs">
      <h1 className="text-center">{t("navbar.aboutUs")}</h1>
      <hr className="mx-auto" width="20%" />
      <p className="px-lg-5 pt-2">{t("aboutUs.body")}</p>
    </Container>
  );
};

export default AboutUs;
