import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import {
  accordionTabs_en,
  accordionTabs_it,
  accordionTabs_sq,
} from "../../data/accordionTabs";

const AccordionServices = () => {
  const { i18n } = useTranslation();

  /**
   * Below the logic to find the right language to display.
   */
  const lngVars = [
    { data: accordionTabs_en, variant: "en" },
    { data: accordionTabs_it, variant: "it" },
    { data: accordionTabs_sq, variant: "sq" },
  ];

  const currentSelLng = lngVars.filter((lng) => lng.variant === i18n.language);

  const [{ data }] = currentSelLng;
  /*********************************************************/
  return (
    <>
      {data.map(({ id, header, body }) => (
        <Accordion key={id} defaultActiveKey={id}>
          <Accordion.Item eventKey={id}>
            <Accordion.Header>{header}</Accordion.Header>
            <Accordion.Body>{body}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  );
};

export default AccordionServices;
