export const accordionTabs_en = [
  {
    id: 1,
    header: "Dental Therapeutics",
    body: "In our studio we provide you with esthetic dental fillings and dental restorations making use of certified materials, in very comfortable and painless procedures.",
  },
  {
    id: 2,
    header: "Prosthodontics",
    body: "In our studio you will find: 1- Removable prosthetics restorations like partial dentures and total dentures, also skeletal prosthesis with attachments. 2- Fixed restorations like crowns and bridges with metal-ceramic also more esthetic restorations like zirconium, palladium and Emax crowns. 3- Elastic prosthesis, innovation nowadays, very comfortable and esthetic for the patients.",
  },
  {
    id: 3,
    header: "Endodontics",
    body: "In our studio you will find specialized treatments of root canals and correct filling of them with biocompatible materials, for a long lasting restoration. The procedure is realized with Endomotor and Apex Locator for precise root canal length. During the procedure periapical x-rays are used (free from the clinic) to control our work for a maximum security.",
  },
  {
    id: 4,
    header: "Periodontology",
    body: "Soft tissue diseases can be treated in our clinic. With our staff support you will have therapeutical and surgical treatments for problems starting from irritation of the gums (gingivitis) and their advanced phases, periodontal socket therapy, taking care of existing restorations till the surgical interventions.",
  },
  {
    id: 5,
    header: "Pediatric Dentistry",
    body: "Children's permanent and primary dentition deserve an adequate treatment for a better oral health. In our studio you will find the patience and the care for this treatments starting with prophylaxis till fixing dental and soft tissue problems.",
  },
  {
    id: 6,
    header: "Surgery and Implantology",
    body: "In cases where the surgery is inevitable we offer specialized treatments for teeth extractions, wisdom tooth extraction, interventions for cystic formations removal, etc. We also offer the most common technique of nowadays, dental implants, (implantology), a procedure realized under local anesthesia using implants of european standards.",
  },
  {
    id: 7,
    header: "Cosmetic Dentistry",
    body: "Nowadays everyone wants a brilliant smile. This is offered in our clinic with mini invasive esthetic services like composite veneers and Emax. As well as you can bleach your teeth with the best materials associated to the Led light. You can also bleach your teeth at home with our recommendation.",
  },
  {
    id: 8,
    header: "Digital Periapical X-ray",
    body: "In our dental studio you will find the x ray (with minimal radiation), of Sirona brand for children and adults to help with our procedures, service which is offered free from the clinic.",
  },
];

export const accordionTabs_sq = [
  {
    id: 1,
    header: "Terapi stomatologjike",
    body: "Në studion tonë ju ofrojmë mbushje dhe restaurime të dhëmbëve me materiale të çertifikuara, në procedura shumë komode dhe pa dhimbje për pacientin.",
  },
  {
    id: 2,
    header: "Protetika stomatologjike",
    body: "Pranë studios tonë do të gjeni: 1- Punime protetike të lëvizshme me proteza parciale dhe totale, nga ato të thjeshta deri te ato të skeletuara me shtojca 2- Punime protetike fikse me kurora dhe ura metal porcelani, deri te ato fikse, shumë estetike, zirkoni, palladiumi dhe EMAX. 3- Proteza elastike, një risi e viteve të fundit mjaft të rehatshme dhe estetike për pacientin.",
  },
  {
    id: 3,
    header: "Endodontia",
    body: "Pranë studios tonë do të gjeni trajtime të specializuara të kanaleve të dhëmbëve dhe mbushje të sakte të tyre me materiale biokompatibile për një restaurim afatgjatë. Procedura realizohet me ENDOMOTOR Apex Locator për gjatësi të saktë të kanalit të rrënjës. Procedura shoqerohet me grafi të vogla periapikale (falas nga klinika) për të kontrolluar punën tonë për një siguri maksimale.",
  },
  {
    id: 4,
    header: "Periodontologjia",
    body: "Sëmundjet e indeve të buta të gojës gjejnë shërim pranë ambjentit tonë. Me ndihmën e doktorëve tanë do të keni trajtime terapeutike dhe kirurgjikale për problemet që fillojnë nga acarimet e mishrave të dhëmbëve dhe fazave të avancuara të tyre, kurimin e xhepave paradontale duke u kujdesur për ritrajtimin e punimeve egzistuese dhe mjekimin e xhepave e më rradhë deri në problematikat e rënda për ndërhyrje kirurgjikale.",
  },
  {
    id: 5,
    header: "Dentistria pediatrike",
    body: "Dhëmbët e qumështit si dhe ato të përhershëm tek fëmijët, meritojnë trajtimin e duhur për një shëndet oral të mirë të mëvonshëm. Pranë nesh do të gjeni kujdesin dhe durimin e duhur për këto trajtime duke u kujdesur fillimisht për profilaksinë pastaj për trajtimin sipas rasteve.",
  },
  {
    id: 6,
    header: "Kirurgjia dentare dhe Implantologjia",
    body: "Në rastet kur kirurgjia është e pashmangshme, pranë nesh do të gjeni trajtim të specializuar nën kujdesin e mjekëve në rastin e heqjes së dhëmbëve dhe dhëmballëve, heqjes kirurgjikale të dhëmballëve të pjekurisë, ndërhyrje per heqjen e formcioneve çistike etj, dhe deri në risinë më të fundit asaj të mbjelljes së dhëmbëve (implantologjia) një procedurë e cila realizohet nën anestezi lokale për vendosjen e implanteve të standarteve botërore.",
  },
  {
    id: 7,
    header: "Kozmetika dentare",
    body: "Në ditët e sotme të gjithë duan një buzëqeshje brilante. Atë mund ta merrni pranë nesh me shërbimet super estetike mini invazive të fasetave të kompozitit apo Emax. Si dhe mund të realizoni zbardhimet e dhëmbëve tuaj me materialet më të mira në prani të llambës brenda në klinikë si dhe nën konsultat tona të vazhdoni zbardhimin në shtëpi.",
  },
  {
    id: 8,
    header: "Radiografia dixhitale periapikale",
    body: "Brënda studios tonë do të gjeni shërbimin e grafisë dentare me sensor (rrezatim minimal), të markës SIRONA për fëmijë dhe të rritur në ndihmë të procedurave tona, shërbim i cili do të ofrohet pa pagesë.",
  },
];

export const accordionTabs_it = [
  {
    id: 1,
    header: "Odontoiatria",
    body: "Nel nostro studio ti forniamo otturazioni dentali estetiche e restauri dentali utilizzando materiali certificati, in procedure molto confortevoli e indolori.",
  },
  {
    id: 2,
    header: "Protesi",
    body: "Nel nostro studio troverai: 1- Restauri protesici rimovibili come protesi parziali e protesi totali o protesi scheletriche con attacchi. 2- Restauri fissi come corone e ponti con metallo-ceramica anche restauri più estetici come corone in zirconio, palladio e Emax. 3- Protesi elastiche, innovazione oggigiorno, molto confortevole ed estetiche per i pazienti.",
  },
  {
    id: 3,
    header: "Endodonzia",
    body: "Nel nostro studio troverai trattamenti specializzati dei canali radicolari e il loro corretto riempimento con materiali biocompatibili, per un restauro duraturo. La procedura è realizzata con Endomotor e Apex Locator per una precisa lunghezza del canale radicolare. Durante la procedura vengono utilizzate radiografie periapicali (gratuite) per controllare il nostro lavoro per una massima sicurezza.",
  },
  {
    id: 4,
    header: "Parodontologia",
    body: "Le malattie dei tessuti molli possono essere trattate nella nostra clinica. Con il supporto del nostro personale avrai a disposizione trattamenti terapeutici e chirurgici per problematiche a partire dall'irritazione delle gengive (gengivite) e loro fasi avanzate, alveoterapia parodontale, prendendosi cura dei restauri esistenti fino agli interventi chirurgici.",
  },
  {
    id: 5,
    header: "Odontoiatria Pediatrica",
    body: "La dentizione permanente e primaria dei bambini merita un trattamento adeguato per una migliore salute orale. Nel nostro studio troverai la pazienza e la cura per questi trattamenti a partire dalla profilassi fino alla risoluzione dei problemi dentali e dei tessuti molli.",
  },
  {
    id: 6,
    header: "Chirurgia e Implantologia",
    body: "Nei casi in cui l'intervento chirurgico è inevitabile offriamo trattamenti specializzati per estrazioni dentarie, estrazioni di denti del giudizio, interventi per la rimozione di formazioni cistiche, ecc. Offriamo anche la tecnica più comune oggigiorno, impianti dentali, (implantologia), una procedura realizzata in anestesia locale utilizzando impianti di standard europei.",
  },
  {
    id: 7,
    header: "Odontoiatria Estetica",
    body: "Oggigiórno tutti vogliono un sorriso brillante. Questo è offerto nella nostra clinica con servizi estetici mini invasivi come faccette in composito ed Emax. Così come puoi sbiancare i denti con i migliori materiali associati alla luce a Led. Puoi anche sbiancare i denti a casa con la nostra raccomandazione.",
  },
  {
    id: 8,
    header: "Radiografia Periapicale Digitale",
    body: "Nel nostro studio dentistico troverai la radiografia (con radiazioni minime), del marchio Sirona per bambini e adulti per aiutare con le procedure, servizio che viene offerto gratuitamente dalla clinica.",
  },
];
