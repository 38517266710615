import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import Map from "./Map";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Container as="section" fluid id="contact">
      <h1 className="text-center">{t("navbar.contact")}</h1>
      <Row className="pt-4 justify-content-md-center">
        <Col lg={6} className="mb-3 mb-lg-0">
          <Map />
        </Col>

        <Col lg={4}>
          <Container
            className="info-card d-flex flex-column align-items-center justify-content-evenly rounded"
            fluid
          >
            <FontAwesomeIcon icon="phone-alt" size="2x" />
            <h2>
              <a href="tel:+355696511116">+355-6965-11116</a>
            </h2>
            <h5>
              <FontAwesomeIcon icon="map-marker-alt" />
              &nbsp;&nbsp;&nbsp;Rr Elbasanit, Tirane, AL
            </h5>
            <h5>
              <FontAwesomeIcon icon="envelope" />
              &nbsp;&nbsp;&nbsp;
              <a href="mailto:dr.shkenza@gmail.com">dr.shkenza@gmail.com</a>
            </h5>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
