/**
 * ************************************************
 * Below the code to import gallery folder images *
 * ************************************************
 */

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/images/gallery", false, /\.(png|jpe?g|svg)$/)
);
/**************************************************/
const imgName = Object.keys(images);

export const PHOTOS = imgName
  .map((name) => images[name].default)
  .map((src, index) => ({
    original: src,
    thumbnail: src,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: `gallery image ${index++}`,
    thumbnailAlt: `thumbnail gallery image ${index++}`,
  }));
