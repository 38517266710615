import { Formik } from "formik";
import React, { useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const HomeForm = () => {
  const [serverMsg, setServerMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const recaptchaRef = useRef();
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const successArr = ["Message Sent", "Mesazhi u Dërgua", "Messaggio Inviato"];

  /**
   * Below is a part of the validation form as in this article:
   * https://hackernoon.com/building-react-forms-with-formik-yup-and-react-bootstrap-with-a-minimal-amount-of-pain-and-suffering-1sfk3xv8
   */
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, t("home.yupSchema.name.min"))
      .max(100, t("home.yupSchema.name.max"))
      .required(t("home.yupSchema.name.required")),
    phone: yup
      .string()
      .matches(phoneRegExp, t("home.yupSchema.phone.matches"))
      .required(t("home.yupSchema.phone.required")),
    email: yup
      .string()
      .email(t("home.yupSchema.email.email"))
      .max(100, t("home.yupSchema.email.max"))
      .required(t("home.yupSchema.email.required")),
    message: yup.string(),
  });

  return (
    <Card className="text-center text-white mb-3 h-100">
      <Card.Body className="rounded">
        <Card.Title>
          <h1>{t("home.bookTitle")}</h1>
        </Card.Title>
        <hr className="mx-auto" width="20%" />
        <Container
          fluid
          className="p-0"
          style={{ height: "58px", marginBottom: "16px" }}
        >
          <Alert
            variant={successArr.includes(serverMsg) ? "success" : "danger"}
            show={showAlert}
            dismissible
            onClose={() => setShowAlert(false)}
          >
            {serverMsg}
          </Alert>
        </Container>
        {/* Sets initial values for form inputs */}
        <Formik
          initialValues={{ name: "", phone: "", email: "", message: "" }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);

            /**
             * Recaptcha token to be passed in the backend and recaptcha reset()
             * https://www.youtube.com/watch?v=vrbyaOoZ-4Q
             */
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            /***********************/

            const response = await fetch("/send", {
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
              },
              body: JSON.stringify({ values, token, language }),
            });

            if (response.status === 500) {
              setServerMsg(t("home.err500Msg"));
              setShowAlert(true);
            }

            // Resets form after submission is complete
            resetForm();

            const result = await response.json();
            setServerMsg(result.status);
            setShowAlert(true);

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Label visuallyHidden={true}>
                      {t("home.name")}
                    </Form.Label>
                    <Form.Control
                      /* This name property is used to access the value of the form element via values.nameOfElement */
                      name="name"
                      placeholder={t("home.name")}
                      /* Set onChange to handleChange */
                      onChange={handleChange}
                      /* Set onBlur to handleBlur */
                      onBlur={handleBlur}
                      value={values.name}
                      isValid={touched.name && !errors.name}
                      /* Check if the name field (this field) has been touched and if there is an error, if so the errors.name will be shown as an error in the Form.Control.Feedback otherwise will be valid */
                      isInvalid={touched.name && errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label visuallyHidden={true}>
                      {t("home.phone")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder={t("home.phone")}
                      inputMode="tel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={touched.phone && errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3 mb-lg-0" controlId="formEmail">
                    <Form.Label visuallyHidden={true}>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      inputMode="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formTextArea">
                    <Form.Label visuallyHidden={true}>
                      {t("home.message")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      placeholder={t("home.message")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                  </Form.Group>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                  <Button
                    className="mb-3 mb-lg-0"
                    variant="success"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("home.button")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
      <Card.Footer className="text-muted text-start">
        {t("home.recaptchaMsg")}
        <a href="https://policies.google.com/privacy">
          {t("home.privacy")}
        </a>{" "}
        {t("home.and")}
        <a href="https://policies.google.com/terms">{t("home.TOS")}</a>.
      </Card.Footer>
    </Card>
  );
};

export default HomeForm;
