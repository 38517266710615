import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import AccordionServices from "./AccordionServices";
import TableServices from "./TableServices";

const Services = () => {
  const { t } = useTranslation();

  return (
    <Container as="section" fluid id="services">
      <h1 className="text-center">{t("navbar.services")}</h1>
      <Row className="pt-4 justify-content-md-center">
        <Col lg={6} className="mb-5 mb-lg-0">
          <AccordionServices />
        </Col>

        <Col lg={4}>
          <TableServices />
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
