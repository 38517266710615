import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

const currentDate = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container as="footer" fluid>
      <div className="text-secondary d-flex flex-column flex-sm-row justify-content-between px-md-5 py-md-3">
        <span>
          © 2011-{currentDate} {t("footer.copyRights")}
        </span>
        <div style={{ marginRight: "170px" }}>
          <a
            href="https://www.facebook.com/dr.shkenza/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "facebook-f"]} />
          </a>
          &nbsp;&nbsp;
          <a
            href="https://www.instagram.com/studio_dentare_dr_shkenza/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
