import { useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";

const languages = [
  {
    code: "sq",
    name: "Shqip",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "it",
    name: "Italiano",
  },
];

const Language = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("documentTitle");
  }, [t]);

  const handleSelect = (eventKey) => i18n.changeLanguage(eventKey);

  const flagsLogic =
    i18n.language === "en"
      ? "us"
      : i18n.language === "sq"
      ? "al"
      : i18n.language;

  return (
    <NavDropdown
      align="end"
      title={<span className={`me-1 flag-icon flag-icon-${flagsLogic}`}></span>}
      id="nav-dropdown"
      onSelect={handleSelect}
      className="py-0"
    >
      {languages.map(({ code, name }) => {
        const flagsLogic = code === "en" ? "us" : code === "sq" ? "al" : code;
        const activeLogic = i18n.language === code;

        return (
          <NavDropdown.Item key={code} eventKey={code} active={activeLogic}>
            <span className={`me-3 flag-icon flag-icon-${flagsLogic}`}></span>
            {name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default Language;
