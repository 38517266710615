import team1 from "../../assets/images/team1-2.jpg";
import team2 from "../../assets/images/team2-1.jpg";
import team3 from "../../assets/images/team3-1.jpg";

export const teamMembers_en = [
  {
    id: 1,
    img: team1,
    alt: "Dr. Irsid Shkenza image",
    name: "Dr. Irsid Shkenza",
    description:
      "Graduated in 2010 at the University of Tirana, Faculty of Medicine, Dentistry branch. Specialized in the Czech republic for oral surgery and implantology. Also, covers the field of dental prosthetics!",
  },
  {
    id: 2,
    img: team2,
    alt: "Dr. Eneida Voda image",
    name: "Dr. Eneida Voda",
    description:
      "Graduated in 2015 at the University of Medicine, Faculty of Dentistry. Dr Eni deals mainly with dentistry therapy, aesthetic restorations and pediatric dentistry!",
  },
  {
    id: 3,
    img: team3,
    alt: "Dr. Lola Karkanaqe image",
    name: "Dr. Lola Karkanaqe",
    description:
      "Graduated in 2019 at the Albanian University Faculty of Dentistry. Dr Lola deals in aesthetic dentistry, bleaching and Pediatric dentistry!",
  },
];

export const teamMembers_sq = [
  {
    id: 1,
    img: team1,
    alt: "Dr. Irsid Shkenza foto",
    name: "Dr. Irsid Shkenza",
    description:
      "Diplomuar në vitin 2010 në Universitetin e Tiranës, Fakulteti i Mjekësisë, dega Stomatologji. Specializuar në Republikën Çeke për kirurgji orale dhe implantologji. Gjithashtu, mbulon fushën e protetikës dentare!",
  },
  {
    id: 2,
    img: team2,
    alt: "Dr. Eneida Voda foto",
    name: "Dr. Eneida Voda",
    description:
      "Diplomuar në vitin 2015 në Universitetin e Mjekësisë, Fakulteti i Stomatologjisë. Doktoreshë Eni merret kryesisht me terapi stomatologjike, restaurime estetike dhe stomatologji pediatrike!",
  },
  {
    id: 3,
    img: team3,
    alt: "Dr. Lola Karkanaqe foto",
    name: "Dr. Lola Karkanaqe",
    description:
      "Diplomuar në vitin 2019 në Universitetin e Mjekësisë, Fakulteti i Stomatologjisë. Doktoreshë Lola merret me stomatologjinë estetike, zbardhim dhëmbësh dhe stomatologji pediatrike!",
  },
];

export const teamMembers_it = [
  {
    id: 1,
    img: team1,
    alt: "Dott Irsid Shkenza imagine",
    name: "Dott Irsid Shkenza",
    description:
      "Laureato nel 2010 presso l'Università di Tirana, Facoltà di Medicina, corso di laurea Odontoiatria. Specializzato nella Repubblica Ceca per chirurgia orale e implantologia. Copre anche il campo della protesi dentale!",
  },
  {
    id: 2,
    img: team2,
    alt: "Dott.ssa Eneida Voda imagine",
    name: "Dott.ssa Eneida Voda",
    description:
      "Laureata nel 2015 presso l'Università di Tirana, Facoltà di Medicina, corso di laurea Odontoiatria. La Dott.ssa Eni si occupa principalmente di terapia odontoiatrica, restauri estetici e odontoiatria pediatrica!",
  },
  {
    id: 3,
    img: team3,
    alt: "Dott.ssa Lola Karkanaqe imagine",
    name: "Dott.ssa Lola Karkanaqe",
    description:
      "Laureata nel 2019 presso l'Università di Tirana, Facoltà di Medicina, corso di laurea Odontoiatria. La Dott.ssa Lola si occupa di odontoiatria estetica, sbiancamento e odontoiatria pediatrica!",
  },
];
