import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import Language from "./Language";
import NavInfoBar from "./NavInfoBar";

const Header = () => {
  const { t } = useTranslation();

  return (
    <Container as="header" fluid className="fixed-top">
      <NavInfoBar />

      <Navbar collapseOnSelect expand="lg" variant="light" className="ps-3">
        <Navbar.Brand href="#home">Dr Shkenza</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <Nav.Link href="#aboutUs">{t("navbar.aboutUs")}</Nav.Link>
            <Nav.Link href="#services">{t("navbar.services")}</Nav.Link>
            <Nav.Link href="#gallery">{t("navbar.gallery")}</Nav.Link>
            <Nav.Link href="#ourTeam">{t("navbar.ourTeam")}</Nav.Link>
            <Nav.Link href="#tourism">{t("navbar.tourism")}</Nav.Link>
            <Nav.Link href="#videos">{t("navbar.videos")}</Nav.Link>
            <Nav.Link href="#contact">{t("navbar.contact")}</Nav.Link>
            <Language />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default Header;
